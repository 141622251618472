import { Routes } from './routes.js';

export const FACEBOOK_PAGE = 'powerbaxllc';

export const CONTACT_TYPE_OPTIONS = {
  email: 'Email',
  phone: 'Phone'
};

export const YES_NO_OPTIONS = {
  yes: 'Yes',
  no: 'No'
};

export const SERVICE_OPTIONS = {
  'install': 'New Installation',
  'annual': 'Annual Service',
  'issue': 'Issue with Generator',
  'other': 'Other'
}

export const PAYMENT_TYPE_OPTIONS = {
  'card': 'Credit/Debit Card',
  'apple': 'Apple Pay',
  'google': 'Google Pay'
};

export const NAV_ITEMS = Object.entries({
  [Routes.HOME]: 'Home',
  [Routes.SERVICE]: 'Request A Service Or Quote',
  [Routes.HELP]: 'Help & FAQs',
  [Routes.ABOUT]: 'About',
  [Routes.CONTACT]: 'Contact',
  [Routes.PAY]: 'Pay Online'
});