import loadConfig from '../loadConfig.js';

let loadingPromise;

const loadPlaceAutocomplete = async () => {
  if (loadingPromise) return loadingPromise;

  loadingPromise = new Promise(async (resolve, reject) => {
    const { maps: { apiKey } } = await loadConfig();

    const script = document.createElement('script');
    script.id = 'google-maps';

    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

    script.onload = () => { resolve(); }
    script.onerror = () => { reject(); }

    document.body.appendChild(script);
  });

  return loadingPromise;
};

export default loadPlaceAutocomplete;