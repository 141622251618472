import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import eatEvent from '../../utils/eatEvent.js';
import router from '../../router.js';

@customElement('swd-a')
class SwdA extends LitElement {
  static styles = css`
    :host {
      cursor: pointer;
      display: inline;
      text-decoration: underline;
    }
    
    a {
      all: inherit;
      padding: 0;
      margin: 0;
      border: 0;
    }
  `;

  @property({ type: String })
  href;

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.handleClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.handleClick);
  }

  render() {
    return html`
      <a href=${this.href}>
        <slot></slot>
      </a>
    `;
  }

  handleClick = event => {
    event.preventDefault();

    router.navigate(this.href);
  };
}