import { html } from 'lit';

export const Routes = {
  HOME: 'home',
  ABOUT: 'about',
  SERVICE: 'service',
  HELP: 'help',
  PAY: 'pay',
  CONTACT: 'contact',
  UPLOAD_IMAGES: 'upload-images-no-id',
  UPLOAD_IMAGES_NO_ID: 'upload-images',
  NOT_FOUND: 'not-found',
  ADMIN_LOGIN: 'login',
  ADMIN_POSTCARD: 'postcard',
  ADMIN_MAP: 'map'
};

/** @type {Object.<string, Route>} */
const routes = {
  [Routes.HOME]: {
    path: '/',
    load: async () => import('./components/views/home-view.js'),
    render: () => html`<home-view></home-view>`
  },
  [Routes.SERVICE]: {
    path: '/service',
    title: 'Request A Service',
    load: async () => import('./components/views/service-view.js'),
    render: () => html`<service-view></service-view>`
  },
  [Routes.HELP]: {
    path: '/help',
    title: 'Help & FAQs',
    load: async () => import('./components/views/help-view.js'),
    render: () => html`<help-view></help-view>`
  },
  [Routes.CONTACT]: {
    path: '/contact',
    title: 'Contact Us',
    load: async () => import('./components/views/contact-view.js'),
    render: () => html`<contact-view></contact-view>`
  },
  [Routes.PAY]: {
    path: '/pay',
    title: 'Pay Online',
    load: async () => import('./components/views/pay-view.js'),
    render: () => html`<pay-view></pay-view>`
  },
  [Routes.ABOUT]: {
    path: '/about',
    title: 'About PowerBax',
    load: async () => import('./components/views/about-view.js'),
    render: () => html`<about-view></about-view>`
  },
  [Routes.UPLOAD_IMAGES_NO_ID]: {
    path: '/upload',
    title: 'Upload Images',
    load: async () => import('./components/views/upload-view.js'),
    render: () => html`<upload-view></upload-view>`
  },
  [Routes.UPLOAD_IMAGES]: {
    path: '/upload/:id',
    title: 'Upload Images',
    load: async () => import('./components/views/upload-view.js'),
    render: ({ id }) => html`<upload-view request-id=${id}></upload-view>`
  },
  [Routes.NOT_FOUND]: {
    notFoundRoute: true,
    path: '/not-found',
    title: 'Not Found',
    load: async () => import('./components/views/not-found-view.js'),
    render: () => html`<not-found-view></not-found-view>`
  },
  [Routes.ADMIN_LOGIN]: {
    path: '/admin/login',
    title: 'Login',
    load: async () => import('./components/views/admin/login-view.js'),
    render: () => html`<login-view></login-view>`
  },
  [Routes.ADMIN_POSTCARD]: {
    path: '/admin/postcard',
    title: 'Postcard',
    load: async () => import('./components/views/admin/postcard-view.js'),
    render: () => html`<postcard-view></postcard-view>`
  },
  [Routes.ADMIN_MAP]: {
    path: '/admin/map',
    title: 'Map',
    load: async () => import('./components/views/admin/map-view.js'),
    render: () => html`<map-view></map-view>`
  }
};

export default routes;