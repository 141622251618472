import './router.js';
import './components/swd-app';
import loadSquareScript from './utils/square/loadSquareScript.js';
import loadPlaceAutocomplete from './utils/google/loadPlaceAutocomplete.js';

(async () => {
  const isProduction = await loadSquareScript();
  console.log('Square script loaded: ', isProduction ? 'production' : 'sandbox');

  await loadPlaceAutocomplete();
})();