import routes from './routes.js';
import eatEvent from './utils/eatEvent.js';
import queryParamsToString from './utils/queryParamsToString.js';

/**
 * @typedef Route
 * @property {boolean?} [notFoundRoute=false] Indicates that this route should be used for otherwise unmatched paths.
 * - If multiple routes are added with `notFound = true`, the last given will be used.
 * @property {string?} [path=] The path to match for this route.
 * - path can contain parameters, denoted with a semicolon, which is also the key that should be used when
 *   providing params. E.g., /image/:imageId
 * @property {function():Promise} load The method to call to load the anything necessary.
 * @property {function(Object.<string, string>):import('lit').html} render The method that returns a Lit html
 *   object to render the view.
 * - This method is given any parameters that are in the path.
 * @property {RegExp} [pattern=] Created when adding the route, this is the pattern used to match again.
 * @property {Object.<string, string>} [params=] The values of the matches parameters, only available when calling getRoute()
 * @property {boolean} [loaded=false] Indicates if this route has been loaded.
 * @property {string} [name=] The name of the route.
 */

/**
 * @param {string} path
 * @returns {RegExp}
 */
const buildRoutePattern = path =>
  new RegExp(`^\/?${
    path.replace(/(^\/|\/$)/, '')
      .replace(/:([^\/]+)/g, '(?<$1>[^\/]+)')
  }\/?$`);

const routesEqual = (a, b) =>
  a && b
    && a.name === b.name
    && Object.values(a.params).length === Object.values(b.params).length
    && Object.entries(a.params || [])
      .every(([key, value]) => value === b.params?.[key]);

export class Router {
  /** @type {Object.<string, Route>} */
  routes = {};

  /** @type {Route} */
  notFoundRoute = undefined;

  /** @type {Boolean} */
  autoLoad = true;

  /** @type {Route} */
  lastRouteReadied;

  constructor() {
    window.addEventListener('popstate', this.loadCurrent);
    window.addEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
  }

  loadCurrent = async (event) => {
    const route = this.getRoute();

    if (routesEqual(route, this.lastRouteReadied)) return; // same route

    document.dispatchEvent(new CustomEvent('route-changed', { detail: { route } }));

    if (this.autoLoad && route) {
      // noinspection JSIgnoredPromiseFromCall
      await this.#doLoad(route);

      document.dispatchEvent(new CustomEvent('route-ready', { detail: { route } }));
      this.lastRouteReadied = route;
    }
  };

  /**
   * Adds a route.
   * @param {string} name
   * @param {Route} route
   */
  addRoute(name, route) {
    if (route.notFoundRoute) {
      route.name = 'not-found';
      this.notFoundRoute = route;
    }

    if (!route.path) return;

    this.routes[name] = {
      ...route,
      name,
      pattern: buildRoutePattern(route.path)
    };
  }

  /**
   * Adds routes.
   * @param {Object.<string, Route>} routes
   */
  addRoutes(routes) {
    Object.entries(routes)
      .forEach(([name, route]) => this.addRoute(name, route));
  }

  /**
   * Gets the route based on a path.
   * @param {string} path The path to get the route for. Defaults to the current path.
   * @return {Route} The route object that matches the given path.
   */
  getRoute(path = document.location.pathname) {
    const route = Object.values(this.routes)
      .find(({ pattern }) => pattern.test(path));

    if (!route) return { ...this.notFoundRoute };

    const params = path.match(route.pattern).groups ?? {}

    return {
      ...route,
      params
    };
  }

  /**
   * Calls the load() function on the matching route.
   * @param {string} path The path to get the route for.
   * @returns {Route} The route that was loaded.
   */
  async load(path = document.location.pathname) {
    return this.#doLoad(this.getRoute(path));
  }

  /**
   * Navigates to the given route name with the given parameters.
   * @param {string} path The path to navigate to.
   * @param {{}} state= The state for the pushState.
   */
  navigate(path, state = {}) {
    if (!this.dispatchBeforeNav()) return;

    window.history.pushState(state, '', path);

    window.dispatchEvent(new PopStateEvent('popstate', { state }));
  }

  generate(routeName, parameters = {}, queryParams = {}) {
    const route = this.routes[routeName];

    if (!route) {
      throw new Error(`Unknown route: ${routeName}`);
    }

    return route.path?.replace(/:([^\/]+)/g, (_, key) => parameters[key])
      + queryParamsToString(queryParams);
  }

  /**
   * @TODO Probably delete this
   * Changes the hash on the current page. Triggers hashchanged event.
   * @param {string} hash The new hash (with or without the initial #)
   * @param {{}} state The state for the pushState.
   */
  changeHash(hash, state = {}) {
    const newHash = (hash === '' || hash === '#')
      ? ''
      : `${hash}`.replace(/^#/, '');

    if (newHash === location.hash) return; // no change

    const oldURL = location.href;
    const newURL = `${location.protocol}//${location.host}${location.pathname}#${newHash}`;

    window.history.pushState(state, '', `${location.pathname}#${newHash}`);
    window.dispatchEvent(new HashChangeEvent('hashchange', { newURL, oldURL }));
  }

  async #doLoad(route) {
    if (!route.loaded) {
      await route.load();
      this.routes[route.name].loaded = true;
      route.loaded = true;
    }

    return route;
  }

  dispatchBeforeNav() {
    return window.dispatchEvent(new CustomEvent('beforenav', { cancelable: true }));
  }

  handleBeforeUnload(event) {
    if (!this.dispatchBeforeNav()) {
      event.preventDefault();
      return event.returnValue = '';
    }
  }
}

const router = new Router();
router.addRoutes(routes);

// noinspection JSIgnoredPromiseFromCall
router.loadCurrent();

export const generate = ::router.generate;
export const navigate = ::router.navigate;

export default router;