import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import common from '../styles/common.js';
import { generate } from '../router.js';
import { classMap } from 'lit/directives/class-map.js';
import { NAV_ITEMS } from '../constants.js';

@customElement('swd-footer')
class SwdFooter extends LitElement {
  static styles = [
    common,
    css`
      :host {
        background: #EEE;
        display: block;
      }
      
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      
      li {
        display: inline;
      }
      
      swd-a.nav-link {
        text-decoration: none;
        color: #333;
      }
      
      swd-a.nav-link:hover {
        text-decoration: underline;
      }

      @media (max-width: 767px) {
        .nav {
          display: none;
        }
      }
    `
  ];

  render() {
    return html`
      <footer>
        <div class="container-md pt-5 pb-4">
          <ul class="mb-4 nav">
            ${NAV_ITEMS.map(([routeName, text]) => html`
              <li>
                <swd-a
                  class="nav-link ${classMap({ active: this.activeRouteName === routeName })}"
                  href=${generate(routeName)}
                >${text}</swd-a>
              </li>
            `)}
          </ul>
          <p class="text-center">
            Copyright &copy; PowerBax, LLC
          </p>
        </div>
      </footer>
    `;
  }
}