import { css } from 'lit';
import adoptStyleSheet from '../utils/adoptStyleSheet.js';

const common = [
  adoptStyleSheet('link#bootstrap'),
  adoptStyleSheet('link#bootstrap-icons'),
  adoptStyleSheet('link#google-fonts'),
  adoptStyleSheet('link#theme')
];

export default common;