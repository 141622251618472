import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import common from '../styles/common.js';
import './common/swd-a.js';
import router, { generate } from '../router.js';
import { NAV_ITEMS } from '../constants.js';
import { Routes } from '../routes.js';

@customElement('swd-nav')
class SwdNav extends LitElement {
  static styles = [
    common,
    css`
      nav {
        justify-content: center;
      }
      
      header {
        box-shadow: 0 0 5px 0 #666;
        z-index: 10000;
      }
      
      swd-a {
        flex: 1;
      }

      @media (min-width: 992px) {
        swd-a.nav-link {
         --bs-navbar-nav-link-padding-x: 20px;
        }
      }
      
      swd-a img.full {
        display: none;
        width: 250px;
        height: auto;
        margin: 0;
      }
      
      swd-a img.small {
        display: inline-block;
        width: 150px;
        height: auto;
        margin: 0;
      }
      
      .nav-item:hover {
        text-decoration: underline;
      }
      
      #request-consult button {
        display: inline-block;
      }
      
      #request-consult {
        display: none;
        flex: 1;
        text-align: right;
      }
      
      @media (min-width: 992px) {
        #request-consult {
          display: block;
        }
        
        #request-consult-nav {
          display: none;
        }
        
        swd-a img.full {
          display: inline-block;
        }

        swd-a img.small {
          display: none;
        }
      }
    `
  ];

  @property()
  collapsed = true;

  @property()
  activeRouteName = undefined;

  connectedCallback() {
    super.connectedCallback();

    this.activeRouteName = router.getRoute().name;
    window.addEventListener('popstate', this.handleUpdateActive);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('popstate', this.handleUpdateActive);
  }

  render() {
    return html`
      <header class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <swd-a href="/" class="navbar-brand">
            <img
              src="public/assets/images/logo-horizontal.png"
              alt="PowerBax - Standby Power Systems"
              width="499"
              height="215"
              class="align-middle full"
            >
            <img
              src="public/assets/images/logo-horizontal-small.png"
              alt="PowerBax - Standby Power Systems"
              width="308"
              height="316"
              class="align-middle small"
            >
          </swd-a>
          <button
            class="navbar-toggler"
            type="button"
            @click=${this.handleToggleCollapse}
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <nav class="navbar-collapse ${classMap({ collapse: this.collapsed })}">
            <ul class="navbar-nav">
              ${NAV_ITEMS.map(([routeName, text]) => html`
                <li class="nav-item">
                  <swd-a
                    @click=${this.handleLinkClick}
                    class="nav-link ${classMap({ active: this.activeRouteName === routeName })}"
                    href=${generate(routeName)}
                  >
                    ${text}
                  </swd-a>
                </li>
              `)}
              <li id="request-consult-nav">
                ${this.renderRequestConsultButton()}
              </li>
            </ul>
          </nav>
          <div id="request-consult">
            ${this.renderRequestConsultButton()}
          </div>
        </div>
      </header>
    `;
  }

  renderRequestConsultButton() {
    return html`
      <a class="btn btn-primary" href=${generate(Routes.SERVICE)}>Request A Service Or Quote</a>
    `;
  }

  handleLinkClick = () => {
    this.collapsed = true;
  }

  handleUpdateActive = () => {
    this.activeRouteName = router.getRoute().name;
  }

  handleToggleCollapse() {
    this.collapsed = !this.collapsed;
  }
}