import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './common/swd-a.js';
import common from '../styles/common.js';
import './swd-nav.js';
import './swd-footer.js';

@customElement('swd-app')
class SwdApp extends LitElement {
  @property({ type: Object, attribute: false })
  route;

  @property()
  scrollToHashOnUpdate = false;

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('route-ready', this.handleRouteReady);
    window.addEventListener('hashchange', this.handleHashChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('route-ready', this.handleRouteReady);
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  updated(changed) {
    if (changed.has('route')) {
      this.route?.load().then(() => { this.scrollToHashOnUpdate = true; });
    }

    if (changed.has('scrollToHashOnUpdate') && this.scrollToHashOnUpdate) {
      this.scrollToCurrentHash('auto');
      this.scrollToHashOnUpdate = false;
    }
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <swd-nav></swd-nav>
      <div>
        ${this.route?.render(this.route?.params)}
        <swd-footer></swd-footer>
      </div>
    `;
  }

  handleRouteReady = ({ detail: { route } }) => {
    this.route = route;

    document.title = `${route.title ? `${route.title} | ` : ''} PowerBax - Standby Power Systems`;
  };

  handleHashChange = () => {
    this.scrollToCurrentHash();
  };

  scrollToCurrentHash(behavior = 'smooth') {
    const { hash } = location;
    const view = this.querySelector(':nth-child(2)');

    if (!view) return;

    if (hash === '' || hash === '#') {
      view.scrollTo({ top: 0, left: 0, behavior });
      return;
    }

    view.querySelector(hash)
      ?.scrollIntoView({ behavior });
  }
}