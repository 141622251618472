const adoptStyleSheet = (selector) => {
  const existing = (document.adoptedStyleSheets)::Array.prototype.find(
    ({ sourceNode }) => sourceNode?.matches(selector)
  );

  if (existing) return existing;

  const styleSheet = (document.styleSheets)::Array.prototype.find(
    s => s.ownerNode.matches(selector)
  );

  const constructed = new CSSStyleSheet();
  constructed.sourceNode = document.querySelector(selector);

  (styleSheet?.cssRules)::Array.prototype.forEach(
    (rule, index) => constructed.insertRule(rule.cssText, index)
  );

  document.adoptedStyleSheets = [...document.adoptedStyleSheets, constructed];

  return constructed;
};

export default adoptStyleSheet;