import getSquareConfig from './getSquareConfig.js';

const loadSquareScript = async () => {
  const { isProduction } = await getSquareConfig();

  if (document.body.querySelector('#square-api')) Promise.resolve(isProduction); // already loaded

  const script = document.createElement('script');
  script.id = 'square-api';
  script.type = 'text/javascript';
  script.src = isProduction
    ? 'https://web.squarecdn.com/v1/square.js'
    : 'https://sandbox.web.squarecdn.com/v1/square.js';

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onerror = reject;
    script.onload = () => { resolve(isProduction); }
  });
};

export default loadSquareScript;